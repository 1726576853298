import React, { useEffect, useState } from "react";
import InvoiceList from "./invoice/Invoice.tsx";
import { generateInvoices } from "./invoice/utils.tsx";
import StudentService from "../../services/StudentService.js";

const FeeManagementSystem = () => {
  const [students, setStudents] = useState([]);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    // Fetch students from the backend
    StudentService.getAllStudents().then((data) => {
      setStudents(data);
      const invoices = generateInvoices(data);
      setInvoices(invoices);
    });
  }, []);

  return (
    <div>
      Fee Management System
      <div>
        {" "}
        <InvoiceList invoices={invoices} />
      </div>
    </div>
  );
};

export default FeeManagementSystem;
