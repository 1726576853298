import React from "react";
import "./TableSkelton.scss";

function TableSkelton() {
  return (
    <table className="tg">
      <tbody>
        <tr>
          <th className="bw-cly1">
            <div className="line"></div>
          </th>
          <th className="bw-cly1">
            <div className="line"></div>
          </th>
          <th className="bw-cly1">
            <div className="line"></div>
          </th>
          <th className="bw-cly1">
            <div className="line"></div>
          </th>
          <th className="bw-cly1">
            <div className="line"></div>
          </th>
          <th className="bw-cly1">
            <div className="line"></div>
          </th>
          <th className="bw-cly1">
            <div className="line"></div>
          </th>
          <th className="bw-cly1">
            <div className="line"></div>
          </th>
        </tr>
        <tr>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
        </tr>
        <tr>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
        </tr>
        <tr>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
        </tr>
        <tr>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
          <td className="bw-cly1">
            <div className="line"></div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default TableSkelton;
