// src/components/App.js
import React from "react";
import "./App.scss";

import Dashboard from "./dashboard/Dashboard.tsx";

const App = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default App;
