import React, { useState } from "react";

const ImageUploader = (props) => {
  const [file, setFile] = useState();

  const readFileData = (file, event) => {
    const promise = new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
        props.getImageFile(reader.result, file, event);
      };
      reader.onerror = () => {
        reject("unable to read the file");
      };
      reader.readAsDataURL(file);
    });
    return promise;
  };

  const handleInputChange = (event) => {
    event && event.stopPropagation();
    let files = event.target.files;
    let fileReadProcesses = Array.prototype.map.call(files, (file) =>
      readFileData(file, event)
    );
    Promise.all(fileReadProcesses).then((thumbnails) => {
      let filesData = thumbnails.map((thumbnail, index) => ({
        file: files[index],
        thumbnail,
      }));
    });
  };
  return (
    <div className="input-file-container">
      <input
        type="file"
        id={props.id}
        accept={
          props.acceptFileType
            ? props.acceptFileType
            : "image/png, image/jpg, image/jpeg"
        }
        multiple={props.multiple || false}
        onChange={(e) => handleInputChange(e)}
        className="image-upload-button input-file"
      />
      <label htmlFor={props.id} className="input-file-trigger">
        Choose File
      </label>
    </div>
  );
};
export default ImageUploader;
