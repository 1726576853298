import React from "react";
import { TextField, styled } from "@mui/material";

export const TextBoxField = (props) => {
  const {
    label,
    name,
    defaultValue,
    onChange,
    rows,
    value,
    required,
    multiline,
    fullWidth,
    type,
    inputProps,
    onInput,
  } = props;
  return (
    <StyledTextField
      label={label}
      name={name}
      defaultValue={defaultValue}
      value={value}
      type={type}
      onChange={onChange}
      fullWidth={fullWidth}
      rows={rows}
      size="small"
      multiline={multiline}
      required={required}
      inputProps={inputProps}
      onInput={onInput}
    />
  );
};

const StyledTextField = styled(TextField)`
  label {
    font-size: 14px;
  }
  input {
    padding: 10px 5px;
    font-size: 14px;
  }
`;
