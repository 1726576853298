export const generateInvoices = (students) => {
  const invoices = [];
  students.forEach((student) => {
    const invoice = {
      id: student.id,
      name: student.first_name + " " + student.last_name,
      session: student.session,
      tution_fee: student.tution_fee,
      transport_fee: student.transport_fee,
      // Add more invoice details if needed
    };
    invoices.push(invoice);
  });
  return invoices;
};
