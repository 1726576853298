import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Table, TextField } from "@mui/material";
import ConfirmationDialog from "../../components/common/Confirmation.tsx";
import { ReactComponent as EditIcon } from "../../assets/svgIcons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svgIcons/delete.svg";
import { ReactComponent as UpdateIcon } from "../../assets/svgIcons/checked.svg";
import { ReactComponent as CancelIcon } from "../../assets/svgIcons/cancel.svg";
import { ReactComponent as ViewDetailsIcon } from "../../assets/svgIcons/view.svg";
import TableSkelton from "../../components/common/TableSkelton.tsx";

import {
  SubmitButton,
  CancelButton,
} from "../../components/common/Buttons.tsx";
import { TextBoxField } from "../../components/common/TextField.tsx";
import StudentService from "../../services/StudentService.js";
import AccountService from "../../services/AccountService.js";

const defaultFormData = {
  class_name: "", // Nursery
  tution_fee: null,
  slug_name: "", // Nursery
  class_code: "", //
  note: "",
  status: "active",
  section: [],
};

function CreateClass() {
  const [formData, setFormData] = useState(defaultFormData);
  const [classes, setClasses] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditEnabledIndex, setIsEditEnabledIndex] = useState(null);
  const [viewSections, setViewSections] = useState(null);

  useEffect(() => {
    StudentService.getAllClasses().then((data) => {
      setClasses(data);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    AccountService.addClasses(formData).then(() => {
      StudentService.getAllClasses().then((data) => {
        setClasses(data);
      });
    });
  };

  const handleEditClick = (item, index) => {
    setIsEditEnabledIndex(index);
  };
  const handleDelete = (id) => {
    setIsEditEnabledIndex(id);
    setIsOpen(true);
  };
  const handleResetFormField = () => {
    setFormData(defaultFormData);
  };
  const handleCancelEdit = (rowData, index) => {
    // setFormData(rowData);
    StudentService.getAllClasses().then((data) => {
      setClasses(data);
    });
    setIsEditEnabledIndex(null);
  };

  const saveUpdatedField = (key, index) => {
    console.log("ready data to save", classes.length);
    console.log("isEditEnabledIndex:", isEditEnabledIndex);
    if (classes && isEditEnabledIndex >= 0) {
      const params = classes[isEditEnabledIndex];
      AccountService.updateClasses(params).then((data) => {
        console.log("updated successfully", data);
        StudentService.getAllClasses().then((data) => {
          setClasses(data);
        });
      });
      setIsEditEnabledIndex(null);
    }
  };

  const handleRowFieldChange = (e, index) => {
    const { name, value, checked } = e.target;

    const updatedData = classes.map((item, i) => {
      if (i === index) {
        if (name === "status") {
          return { ...item, [name]: checked ? "active" : "inactive" };
        } else {
          return { ...item, [name]: value };
        }
      } else return item;
    });
    setClasses(updatedData);
    const params = updatedData[index];
    AccountService.updateClasses(params).then((data) => {
      StudentService.getAllClasses().then((data) => {
        setClasses(data);
      });
    });
  };
  const handleDialogConfirm = () => {
    console.log("handleDialogConfirm EditEnabledIndex: ", isEditEnabledIndex);
    AccountService.removeClasses(isEditEnabledIndex).then((data) => {
      console.log("deleted successfully", data);
      setIsOpen(false);
      StudentService.getAllClasses().then((data) => {
        setClasses(data);
      });
    });
    //setIsEditEnabledIndex
    setIsEditEnabledIndex(null);
  };
  return (
    <Grid container spacing={0} component={Paper}>
      <ConfirmationDialog
        isOpen={isOpen}
        handleConfirm={handleDialogConfirm}
        handleCancel={() => setIsOpen(false)}
      />
      <Grid container spacing={2} sx={{ m: 2, mb: 4 }}>
        <Grid item xs={12} sm={4}>
          <TextBoxField
            label="Class Title"
            name="class_name"
            value={formData.class_name}
            onChange={handleChange}
            inputProps={{ maxLength: 50 }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextBoxField
            label="Class Slug"
            name="slug_name"
            value={formData.slug_name}
            onChange={handleChange}
            inputProps={{ maxLength: 50 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextBoxField
            label="Class Code"
            name="class_code"
            value={formData.class_code}
            onChange={handleChange}
            inputProps={{ maxLength: 50 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextBoxField
            maxLength={5}
            label="Tution Fees"
            name="tution_fee"
            value={formData.tution_fee}
            onChange={handleChange}
            inputProps={{
              type: "number",
              maxLength: 8,
            }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 8);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextBoxField
            label="Note"
            name="note"
            value={formData.note}
            onChange={handleChange}
            inputProps={{ maxLength: 100 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SubmitButton
            type="submit"
            variant="contained"
            label={"Add Class"}
            handleClick={handleSubmit}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <CancelButton
            type="reset"
            variant="contained"
            label={"Reset"}
            handleClick={handleResetFormField}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      {classes ? (
        <table className={"tableList"}>
          <thead>
            <tr>
              <th>S.N.</th>
              <th style={{ width: "450px" }}>Name</th>
              <th>Tution Fees</th>
              <th>Sections</th>
              <th>ID</th>
              <th>Status</th>
              <th style={{ width: "150px" }}></th>
            </tr>
          </thead>
          <tbody>
            {classes.map((item, i) => (
              <tr key={i + 2}>
                <td>
                  <div>{i}</div>
                </td>
                <td>
                  {!(isEditEnabledIndex === i) && <div>{item.class_name}</div>}
                  {isEditEnabledIndex === i && (
                    <input
                      type="text"
                      name="class_name"
                      className="inputBox"
                      // value={item.class_name}
                      onChange={(e) => handleRowFieldChange(e, i)}
                      defaultValue={item.class_name}
                      maxLength={50}
                    />
                  )}
                </td>
                <td>
                  <div>
                    {isEditEnabledIndex === i ? (
                      <input
                        type="number"
                        name="tution_fee"
                        className="inputBox"
                        // value={item.class_name}
                        onChange={(e) => handleRowFieldChange(e, i)}
                        defaultValue={item.tution_fee}
                        maxLength={8}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 8);
                        }}
                      />
                    ) : (
                      <div>{item.tution_fee}</div>
                    )}
                  </div>
                </td>
                <td>
                  <button
                    className="btn-item"
                    onClick={() => setViewSections(!viewSections)}
                  >
                    {<ViewDetailsIcon width="20" height="20" />}
                  </button>
                  {(viewSections && item.sections) ?? "NA"}
                </td>
                <td>
                  <div>
                    <div>{item.id}</div>
                  </div>
                </td>
                <td>
                  {/* <div>{item.status}</div> */}
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={item.status === "active" ? true : false}
                      name={"status"}
                      onChange={(e) => handleRowFieldChange(e, i)}
                    />
                    <span className="slider round"></span>
                  </label>
                </td>
                <td>
                  <div className="btn-container">
                    {isEditEnabledIndex === i ? (
                      <button
                        className="btn-item"
                        onClick={() => saveUpdatedField(item, i)}
                      >
                        {<UpdateIcon width="20" height="20" />}
                      </button>
                    ) : (
                      <button
                        className="btn-item"
                        onClick={() => handleEditClick(item, i)}
                      >
                        {<EditIcon width="20" height="20" />}
                      </button>
                    )}

                    {isEditEnabledIndex === i ? (
                      <button
                        className="btn-item"
                        onClick={() => handleCancelEdit(item, i)}
                      >
                        {<CancelIcon width="20" height="20" />}
                      </button>
                    ) : (
                      <button
                        className="btn-item"
                        onClick={() => handleDelete(item.id)}
                      >
                        {<DeleteIcon width="20" height="20" />}
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <TableSkelton />
      )}
    </Grid>
  );
}

export default CreateClass;
