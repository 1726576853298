import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Typography,
  FormControl,
  Snackbar,
  Alert,
  Paper,
} from "@mui/material";
import StudentService from "../../services/StudentService.js";
import {
  SubmitButton,
  CancelButton,
} from "../../components/common/Buttons.tsx";
import { TextBoxField } from "../../components/common/TextField.tsx";
import { SelectField } from "../../components/common/Select.tsx";
import { RadioGroupField } from "../../components/common/RadioGroup.tsx";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ImageUploader from "../../components/common/ImageUploader.tsx";
import { upload_image_size } from "../../constant.js";

const steps = [
  "Enter student personal information",
  "Address",
  "Education details",
];

//Personal Details
// Address
//Demography
//Fees Payment

const defaultFormData = {
  first_name: "",
  last_name: "",
  blood_group: "",
  date_of_birth: "",
  gender: "male",
  mother_name: "",
  father_name: "",
  application_date: "",
  admission_date: "",
  class_id: "",
  section_id: "",
  email: "",
  mobile: "",
  address: "",
  note: "",
  profile_image: "",
};
const AddStudentForm = () => {
  const [classes, setClasses] = useState([]);
  const [classesSections, setClassesSections] = useState([]);
  const [formData, setFormData] = useState({ ...defaultFormData });
  const [showAlert, setShowAlert] = useState(false);
  const [studentImage, setStudentImage] = useState();
  const [stepLabel, setStepLabel] = useState(0);

  useEffect(() => {
    StudentService.getAllClasses().then((data) => {
      setClasses(data);
    });

    StudentService.getAllClassSections().then((data) => {
      setClassesSections(data);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      first_name,
      last_name,
      date_of_birth,
      gender,
      mother_name,
      father_name,
      admission_date,
      class_id,
      section_id,
      email,
      mobile,
      address,
      note,
      profile_image,
    } = formData;
    const formDataToSend = new FormData();
    formDataToSend.append("first_name", first_name);
    formDataToSend.append("last_name", last_name);
    formDataToSend.append("date_of_birth", date_of_birth);
    formDataToSend.append("gender", gender);
    formDataToSend.append("mother_name", mother_name);
    formDataToSend.append("father_name", father_name);
    formDataToSend.append("admission_date", admission_date);
    formDataToSend.append("class_id", class_id);
    formDataToSend.append("section_id", section_id);
    formDataToSend.append("email", email);
    formDataToSend.append("mobile", mobile);
    formDataToSend.append("address", address);
    formDataToSend.append("note", note);
    formDataToSend.append("profile_image", profile_image);
    formDataToSend.append("file", profile_image);
    StudentService.addNewStudent(formDataToSend).then((response) => {
      console.log("form api resposne file", response);
      setShowAlert(true);
    });
  };
  const handleClose = () => {
    setShowAlert(false);
  };

  const uploadProfileImage = (filesData, file, event) => {
    if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg"
    ) {
      if (filesData) {
        setFormData((prevData) => ({
          ...prevData,
          profile_image: filesData,
        }));
        setStudentImage(filesData);
      }
    } else {
      console.error("profile image error");
    }
  };

  return (
    <Container sx={{ p: 5 }} component={Paper}>
      <Typography variant="h4" align="center" gutterBottom>
        <Stepper activeStep={stepLabel} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Typography>
      {showAlert && (
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Student added successfully!
          </Alert>
        </Snackbar>
      )}
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextBoxField
              label="First Name"
              name="first_name"
              value={formData.first_name}
              inputProps={{ maxLength: 100 }}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextBoxField
              label="Last Name"
              name="last_name"
              value={formData.last_name}
              inputProps={{ maxLength: 100 }}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextBoxField
              label="Blood Group"
              name="blood_group"
              value={formData.blood_group}
              onChange={handleChange}
              inputProps={{ maxLength: 200 }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextBoxField
              id="outlined-required"
              label="Date of Birth"
              type="date"
              name="date_of_birth"
              value={formData.date_of_birth || new Date()}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextBoxField
              label="Application Date"
              type="date"
              name="application_date"
              value={formData.application_date || new Date()}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextBoxField
              label="Admission Date"
              type="date"
              name="admission_date"
              value={formData.admission_date || new Date()}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextBoxField
              label="Mother Name"
              name="mother_name"
              value={formData.mother_name}
              onChange={handleChange}
              inputProps={{ maxLength: 200 }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextBoxField
              label="Father Name"
              name="father_name"
              value={formData.father_name}
              onChange={handleChange}
              inputProps={{ maxLength: 200 }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <SelectField
              label="Class"
              name="class_id"
              nameId="class_name"
              value={formData.class_id}
              onChange={handleChange}
              listItems={classes}
              size="small"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <SelectField
              label="Section"
              name="section_id"
              nameId="section_name"
              selectvalue={formData.section_id}
              onChange={handleChange}
              listItems={classesSections}
              size="small"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" size="small">
              <RadioGroupField
                row
                label="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                defaultValue={"male"}
              />

              {studentImage && (
                <div>
                  <img
                    srcSet={studentImage}
                    src={`data:image/jpeg;base64,${studentImage}`}
                    alt={"user profile"}
                    loading="lazy"
                    width={200}
                    height={200}
                    style={{ border: "1px solid #000", margin: "4px 0" }}
                  />
                </div>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div>
              <label>Photograph</label>
              <ImageUploader
                limitSize={upload_image_size.limit}
                message={upload_image_size.message}
                id="StudentProfileImage"
                name="profile_image"
                getImageFile={uploadProfileImage}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextBoxField
              variant="outlined"
              required
              fullWidth
              type="email"
              label="Email Address"
              name="email"
              defaultValue={formData.email}
              onChange={handleChange}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextBoxField
              variant="outlined"
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              defaultValue={""}
              onChange={handleChange}
              inputProps={{ maxLength: 11 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextBoxField
              variant="outlined"
              required
              fullWidth
              label="Address"
              name="address"
              defaultValue={""}
              onChange={handleChange}
              inputProps={{ maxLength: 350 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextBoxField
              label="Description"
              name="description"
              defaultValue={formData.note}
              onChange={handleChange}
              fullWidth
              rows={4}
              multiline
              inputProps={{ maxLength: 250 }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <SubmitButton
            type="submit"
            variant="contained"
            label={"Add Student"}
          />
        </Grid>
      </form>
    </Container>
  );
};

export default AddStudentForm;
