import React from "react";
import { Chart } from "react-google-charts";

interface DataChartProps {
  studentChartData: [null];
  chartOptions: object;
}

export function DataChart(props: DataChartProps) {
  const { studentChartData, chartOptions } = props;
  console.log("studentChartData, chartOptions", studentChartData, chartOptions);

  return (
    <div>
      <Chart
        chartType="PieChart"
        data={studentChartData}
        options={chartOptions}
        width={"100%"}
        height={"400px"}
      />
    </div>
  );
}

export default DataChart;
