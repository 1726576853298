import React, { useEffect, useState } from "react";
import { student } from "../../utils/dataTypes";
import StudentService from "../../services/StudentService";
import { Checkbox, Paper, Select, TextField } from "@mui/material";
import {
  AttendanceButton,
  SubmitButton,
} from "../../components/common/Buttons.tsx";
import moment from "moment";
import { gridFilterActiveItemsLookupSelector } from "@mui/x-data-grid";

const AddAttendance = () => {
  const [studentsList, setStudentList] = useState<student[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [studentAttendance, setStudentAttendance] = useState();
  const [isHoliday, setIsHoliday] = useState(false);

  const getAllClasses = async () => {
    const classes = await StudentService.getAllClasses();
  };
  const getAllClassSections = async () => {
    const classes = await StudentService.getAllClassSections();
  };
  const getAttendanceData = async (selectedDate) => {
    const filterData = {
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    const attaindance = await StudentService.getAllStudentAttendance(
      filterData
    ).then((data) => data);
    if (attaindance.data.length > 0) {
      setIsHoliday(attaindance.is_holiday);
      const parseData = attaindance.data.map((data, i) => {
        const {
          student_id,
          student_name,
          class_name,
          section_name,
          father_name,
          is_present,
          is_late,
          on_leave,
          is_holiday,
        } = data;
        return {
          student_id,
          student_name,
          class_name,
          section_name,
          father_name,
          is_present,
          is_late,
          on_leave,
          is_holiday,
        };
      });
      setStudentAttendance(parseData);
    } else {
      getAllStudents();
    }
  };

  const getAllStudents = async () => {
    const students = await StudentService.getAllStudents();
    setStudentList(students);
    console.log("student: ", students);
    setStudentAttendance(parseStudentsForInitAttendanceObj(students));
  };
  useEffect(() => {
    getAllClasses();
    getAllClassSections();
    // getAllStudents();
    getAttendanceData(selectedDate);
  }, []);

  useEffect(() => {
    getAttendanceData(selectedDate);
  }, [selectedDate]);

  const parseStudentsForInitAttendanceObj = (students) => {
    return students.map((student) => {
      const {
        id,
        first_name,
        middle_name,
        last_name,
        class_name,
        section_name,
        father_name,
      } = student;
      const newObj = {
        student_id: id,
        student_name:
          first_name + (middle_name ? " " + middle_name : "") + " " + last_name,
        class_name,
        section_name,
        father_name,
        is_present: false,
        is_late: false,
        on_leave: false,
        is_holiday: isHoliday,
      };
      return newObj;
    });
  };

  const handleSubmitAttendance = () => {
    console.log("handle Save Attendance", {
      date: selectedDate,
    });

    // e.preventDefault();
    StudentService.updateAttendance({
      selected_date: selectedDate,
      data: studentAttendance,
      is_holiday: isHoliday,
    });
  };

  const handleMarkAttendance = (obj, index) => {
    const updatedData =
      studentAttendance &&
      studentAttendance.map((stu, i) => {
        if (i === index) {
          return { ...stu, ...obj };
        } else return stu;
      });
    setStudentAttendance(updatedData);
  };
  return (
    <Paper>
      <h1>Attendance</h1>
      <TextField
        label="Select Date"
        type="date"
        name="date"
        defaultValue={selectedDate}
        value={moment(selectedDate).format("YYYY-MM-DD")}
        onChange={(e) => setSelectedDate(e.target.value)}
        fullWidth
        required
      />
      <SubmitButton
        type="submit"
        variant="contained"
        label={"Save"}
        handleClick={handleSubmitAttendance}
      />
      <div>
        <Checkbox
          checked={isHoliday}
          onChange={() => setIsHoliday(!isHoliday)}
        />
        <label>Mark Holiday</label>
      </div>
      <table className={"tableList"}>
        <thead>
          <tr>
            <th>{"S.N."}</th>
            <th>{"Student Id"}</th>
            <th>{"Student"}</th>
            <th>{"Father Name"}</th>
            <th>{"Attendance"}</th>
            <th>{"Mark Attendance"}</th>
          </tr>
        </thead>
        <tbody>
          {studentAttendance &&
            studentAttendance?.map((student: student, index: number) => (
              <tr>
                <td>{index + 1}</td>
                <td>{student.student_id}</td>
                <td>{student.student_name}</td>
                <td>{student.father_name}</td>
                <td
                  className={
                    isHoliday
                      ? "text-holiday"
                      : student.is_present
                      ? "text-present"
                      : "text-absent"
                  }
                >
                  <div>
                    {isHoliday
                      ? "HOLIDAY"
                      : student.is_present
                      ? student.is_late
                        ? "Present / Late"
                        : "Present"
                      : student.on_leave
                      ? "Absent / Leave"
                      : "Absent"}
                  </div>
                </td>
                <td>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <AttendanceButton
                      disable={isHoliday}
                      label="P"
                      color="green"
                      handleClick={() =>
                        handleMarkAttendance(
                          {
                            is_present: true,
                            is_late: false,
                            on_leave: false,
                          },
                          index
                        )
                      }
                    />
                    <AttendanceButton
                      disable={isHoliday}
                      label="A"
                      color="red"
                      handleClick={() =>
                        handleMarkAttendance(
                          {
                            is_present: false,
                            is_late: false,
                            on_leave: false,
                          },
                          index
                        )
                      }
                    />
                    <AttendanceButton
                      disable={isHoliday}
                      label="L"
                      color="grey"
                      handleClick={() =>
                        handleMarkAttendance(
                          {
                            is_present: true,
                            is_late: true,
                            on_leave: false,
                          },
                          index
                        )
                      }
                    />
                    <AttendanceButton
                      disable={isHoliday}
                      label="Leave"
                      color="grey"
                      handleClick={() =>
                        handleMarkAttendance(
                          {
                            is_present: false,
                            is_late: false,
                            on_leave: true,
                          },
                          index
                        )
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          <tr>footer</tr>
        </tbody>
      </table>
    </Paper>
  );
};

export default AddAttendance;
