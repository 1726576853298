import React, { useState } from "react";
import axios from "axios";
import StudentIDCardContainer from "./StudentIDCardContainer.tsx";
import { baseApiUrl } from "../../constant.js";

function BulkUpload() {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("csvFile", file);

      await axios.post(baseApiUrl + "students/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert("Data uploaded successfully");
    } catch (error) {
      console.error(error);
      alert("Error uploading data");
    }
  };

  return (
    <div>
      <h1>Student Management App</h1>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      <StudentIDCardContainer />
    </div>
  );
}

export default BulkUpload;
