import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import ConfirmationDialog from "../../components/common/Confirmation.tsx";
import { ReactComponent as EditIcon } from "../../assets/svgIcons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svgIcons/delete.svg";
import { ReactComponent as UpdateIcon } from "../../assets/svgIcons/checked.svg";
import { ReactComponent as CancelIcon } from "../../assets/svgIcons/cancel.svg";
import TableSkelton from "../../components/common/TableSkelton.tsx";

import {
  SubmitButton,
  CancelButton,
} from "../../components/common/Buttons.tsx";
import { TextBoxField } from "../../components/common/TextField.tsx";
import AccountService from "../../services/AccountService.js";

const defaultFormData = {
  subject_name: "", // Nursery
  slug_name: "",
  subject_code: "", // Nursery
  note: "",
  status: "active",
};

function AddSubjects() {
  const [formData, setFormData] = useState(defaultFormData);
  const [tableData, setTableData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditEnabledIndex, setIsEditEnabledIndex] = useState(null);

  useEffect(() => {
    AccountService.getAllSubjects().then((data) => {
      setTableData(data);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    AccountService.addSubject(formData).then(() => {
      AccountService.getAllSubjects().then((data) => {
        setTableData(data);
      });
    });
  };

  const handleEditClick = (item, index) => {
    setIsEditEnabledIndex(index);
  };
  const handleDelete = (id) => {
    setIsEditEnabledIndex(id);
    setIsOpen(true);
  };
  const handleResetFormField = () => {
    setFormData(defaultFormData);
  };
  const handleCancelEdit = (rowData, index) => {
    // setFormData(rowData);
    AccountService.getAllSubjects().then((data) => {
      setTableData(data);
    });
    setIsEditEnabledIndex(null);
  };

  const saveUpdatedField = (key, index) => {
    if (tableData && isEditEnabledIndex >= 0) {
      const params = tableData[isEditEnabledIndex];
      AccountService.updateSubject(params).then((data) => {
        AccountService.getAllSubjects().then((data) => {
          setTableData(data);
        });
      });
      setIsEditEnabledIndex(null);
    }
  };

  const handleRowFieldChange = (e, index) => {
    const { name, value, checked } = e.target;

    const updatedData = tableData.map((item, i) => {
      if (i === index) {
        if (name === "status") {
          return { ...item, [name]: checked ? "active" : "inactive" };
        } else {
          return { ...item, [name]: value };
        }
      } else return item;
    });

    setTableData(updatedData);
  };
  const handleDialogConfirm = () => {
    AccountService.removeSubject(isEditEnabledIndex).then((data) => {
      setIsOpen(false);
      AccountService.getAllSubjects().then((data) => {
        setTableData(data);
      });
    });
    setIsEditEnabledIndex(null);
  };
  return (
    <Grid container spacing={0} component={Paper}>
      <ConfirmationDialog
        isOpen={isOpen}
        handleConfirm={handleDialogConfirm}
        handleCancel={() => setIsOpen(false)}
      />
      <Grid container spacing={2} sx={{ m: 2, mb: 4 }}>
        <Grid item xs={12} sm={4}>
          <TextBoxField
            label="Subject Title"
            name="subject_name"
            value={formData.subject_name}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextBoxField
            label="Subject Slug"
            name="slug_name"
            value={formData.slug_name}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextBoxField
            label="Subject Code"
            name="subject_code"
            value={formData.subject_code}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextBoxField
            label="Note"
            name="note"
            value={formData.note}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SubmitButton
            type="submit"
            variant="contained"
            label={"Add Subject"}
            handleClick={handleSubmit}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <CancelButton
            type="reset"
            variant="contained"
            label={"Reset"}
            handleClick={handleResetFormField}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      {tableData ? (
        <table className={"tableList"}>
          <thead>
            <tr>
              <th>S.N.</th>
              <th style={{ width: "450px" }}>Subject Name</th>
              <th>Code</th>
              <th>Note</th>
              <th>Status</th>
              <th style={{ width: "150px" }}></th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, i) => (
              <tr key={i + 2}>
                <td>
                  <div>{i}</div>
                </td>
                <td>
                  {isEditEnabledIndex === i ? (
                    <input
                      type="text"
                      name="subject_name"
                      className="inputBox"
                      onChange={(e) => handleRowFieldChange(e, i)}
                      defaultValue={item.subject_name}
                    />
                  ) : (
                    <div>{item.subject_name}</div>
                  )}
                </td>

                <td>
                  <div>
                    {isEditEnabledIndex === i ? (
                      <input
                        type="text"
                        name="subject_code"
                        className="inputBox"
                        onChange={(e) => handleRowFieldChange(e, i)}
                        defaultValue={item.subject_code}
                      />
                    ) : (
                      <div>{item.subject_code}</div>
                    )}
                  </div>
                </td>
                <td>
                  <div>
                    {isEditEnabledIndex === i ? (
                      <input
                        type="text"
                        name="note"
                        className="inputBox"
                        onChange={(e) => handleRowFieldChange(e, i)}
                        defaultValue={item.note}
                      />
                    ) : (
                      <div>{item.note}</div>
                    )}
                  </div>
                </td>
                <td>
                  {/* <div>{item.status}</div> */}
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={item.status === "active" ? true : false}
                      name={"status"}
                      onChange={(e) => handleRowFieldChange(e, i)}
                    />
                    <span className="slider round"></span>
                  </label>
                </td>
                <td>
                  <div className="btn-container">
                    {isEditEnabledIndex === i ? (
                      <button
                        className="btn-item"
                        onClick={() => saveUpdatedField(item, i)}
                      >
                        {<UpdateIcon width="20" height="20" />}
                      </button>
                    ) : (
                      <button
                        className="btn-item"
                        onClick={() => handleEditClick(item, i)}
                      >
                        {<EditIcon width="20" height="20" />}
                      </button>
                    )}

                    {isEditEnabledIndex === i ? (
                      <button
                        className="btn-item"
                        onClick={() => handleCancelEdit(item, i)}
                      >
                        {<CancelIcon width="20" height="20" />}
                      </button>
                    ) : (
                      <button
                        className="btn-item"
                        onClick={() => handleDelete(item.id)}
                      >
                        {<DeleteIcon width="20" height="20" />}
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <TableSkelton />
      )}
    </Grid>
  );
}

export default AddSubjects;
