import { GridColDef } from '@mui/x-data-grid';
import { HeadCell } from '../types';
const StudentTabColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'profile_image', headerName: 'Image', width: 70 },
    { field: 'first_name', headerName: 'First name', width: 130 },
    { field: 'last_name', headerName: 'Last name', width: 130 },
    {
      field: 'date_of_birth',
      headerName: 'Date of birth',
      type: 'Date',
      width: 90,
    },
    { field: 'gender', headerName: 'Gender', width: 130 },
    { field: 'mother_name', headerName: 'Mother', width: 130 },
    { field: 'father_name', headerName: 'Father', width: 130 },
    { field: 'class_name', headerName: 'Class', width: 130 },
  ];

  const headCells: readonly HeadCell[] = [
    {
      id: "profile_image",
      numeric: true,
      disablePadding: false,
      label: " Image",
    },
   
    {
      id: "first_name",
      numeric: true,
      disablePadding: false,
      label: "Student Name",
    },
    {
      id: "gender",
      numeric: true,
      disablePadding: false,
      label: "Gender",
    },
    
    {
      id: "father_name",
      numeric: true,
      disablePadding: false,
      label: "Father Name",
    },
    {
      id: "class_name",
      numeric: true,
      disablePadding: false,
      label: "Class",
    },
    {
      id: "section_id",
      numeric: true,
      disablePadding: false,
      label: "Section",
    },   
    {
      id: "",
      numeric: false,
      disablePadding: true,
      label: "",
    } 
  ];

export {headCells, StudentTabColumns}