import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  //   makeStyles,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import moment from "moment";
import AccountService from "../../services/AccountService";

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     padding: theme.spacing(3),
//   },
//   avatar: {
//     width: theme.spacing(10),
//     height: theme.spacing(10),
//   },
// }));

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Report = () => {
  let { state } = useLocation();
  const studentData = state;
  const [feeData, setFeeData] = useState([]);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    AccountService.getStudentFees(state.id).then((data) => {
      console.log("fees data", data);
      setFeeData(data);
    });
  }, []);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  console.log("studentData: ", studentData);
  return (
    <Paper>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Avatar
          alt={`${studentData.first_name} ${studentData.last_name}`}
          src={`data:image/jpeg;base64,${studentData.profile_image}`}
          style={{ width: 250, height: 250 }}
        />
      </Grid>
      <Grid item style={{ padding: "5px 5px 5px 25px" }}>
        <Typography variant="h5">{`${studentData.first_name} ${studentData.last_name}`}</Typography>
        <Typography color="textSecondary">{`Date of Birth: ${moment(
          studentData.date_of_birth
        ).format("YYYY-MM-DD")}`}</Typography>
      </Grid>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Fees " {...a11yProps(0)} />
            <Tab label="Attendance" {...a11yProps(1)} />
            <Tab label="Result" {...a11yProps(2)} />
            <Tab label="Homework" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Grid container spacing={2} style={{ padding: "30px" }}>
            {/* Add more student details as needed */}
            <div style={{ position: "relative" }}>
              {/* <Slider {...sliderSettings}> */}
              {feeData &&
                feeData.map((item) => (
                  <div>
                    <Paper
                      style={{
                        margin: "10px 0",
                        padding: "10px",
                        background: "#eeeeee",
                      }}
                    >
                      <div style={{ width: "450px" }}>
                        Fees Month Date:{" "}
                        {moment(item.fee_date).format("DD-MM-YYYY")}
                        <div className="td">Tution fee: {item.tution_fee}</div>
                        <div className="td">
                          Tansport fee: {item.transport_fee}
                        </div>
                        <div className="td">
                          Total Due Fee: {item.total_due_fee}
                        </div>
                      </div>
                    </Paper>
                  </div>
                ))}
              {/* </Slider> */}
            </div>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          Item Two
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Item Three
        </CustomTabPanel>
      </Box>

      {/*  */}

      <Divider style={{ margin: "16px 0" }} />
      {/* Add additional components for more student details or actions */}
    </Paper>
  );
};

export default Report;
