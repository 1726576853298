import React from "react";
import {
  SubmitButton,
  CancelButton,
} from "../../../components/common/Buttons.tsx";

const InvoiceList = ({ invoices }) => {
  const generateInvoice = () => {
    console.log("Generate invoice");
  };
  return (
    <div>
      <h2>Fee Invoices for Session</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Student Name</th>
            <th>Session</th>
            <th>Monthly tuition fee</th>
            <th>Monthly transport fee</th>
            <th>Generte Invoice</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
            <tr key={invoice.id}>
              <td>{invoice.id}</td>
              <td>{invoice.name}</td>
              <td>{invoice.session}</td>
              <td>{invoice.tution_fee}</td>
              <td>{invoice.transport_fee}</td>

              <td>
                {" "}
                <SubmitButton
                  type="submit"
                  variant="contained"
                  label={"Generate Invoice"}
                  handleClick={() => generateInvoice()}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceList;
