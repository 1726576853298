import React, { useState } from "react";

function ImageUploader() {
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    // You can implement the image upload logic here using a service or API
    // For simplicity, we'll just log the base64 data in this example
    console.log(image);
  };

  return (
    <div className="App">
      <h1>Image Uploader</h1>
      <input type="file" onChange={handleImageChange} />
      {image && <img src={image} alt="Uploaded" style={{ maxWidth: "100%" }} />}
      <button onClick={handleUpload}>Upload Image</button>
    </div>
  );
}

export default ImageUploader;
