import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import ConfirmationDialog from "../../components/common/Confirmation.tsx";
import { ReactComponent as EditIcon } from "../../assets/svgIcons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svgIcons/delete.svg";
import { ReactComponent as UpdateIcon } from "../../assets/svgIcons/checked.svg";
import { ReactComponent as CancelIcon } from "../../assets/svgIcons/cancel.svg";
import TableSkelton from "../../components/common/TableSkelton.tsx";

import {
  SubmitButton,
  CancelButton,
} from "../../components/common/Buttons.tsx";
import { TextBoxField } from "../../components/common/TextField.tsx";
import AccountService from "../../services/AccountService.js";

const defaultFormData = {
  id: null,
  route_name: "", // Choma
  distance: "", // 12 KM
  fee_amount: 0, // 800
  driver_id: "",
  note: " ",
};

function AddRoutes() {
  const [formData, setFormData] = useState(defaultFormData);
  const [tableData, setTableData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditEnabledIndex, setIsEditEnabledIndex] = useState(null);

  useEffect(() => {
    AccountService.getAllRoutes().then((data) => {
      setTableData(data);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    AccountService.addRoutes(formData).then(() => {
      AccountService.getAllRoutes().then((data) => {
        setTableData(data);
      });
    });
  };

  const handleEditClick = (item, index) => {
    setIsEditEnabledIndex(index);
  };
  const handleDelete = (id) => {
    setIsEditEnabledIndex(id);
    setIsOpen(true);
  };
  const handleResetFormField = () => {
    setFormData(defaultFormData);
  };
  const handleCancelEdit = (rowData, index) => {
    // setFormData(rowData);
    AccountService.getAllRoutes().then((data) => {
      setTableData(data);
    });
    setIsEditEnabledIndex(null);
  };

  const saveUpdatedField = (key, index) => {
    if (tableData && isEditEnabledIndex >= 0) {
      const params = tableData[isEditEnabledIndex];
      AccountService.updateRoutes(params).then((data) => {
        AccountService.getAllRoutes().then((data) => {
          setTableData(data);
        });
      });
      setIsEditEnabledIndex(null);
    }
  };

  const handleRowFieldChange = (e, index) => {
    const { name, value, checked } = e.target;

    const updatedData = tableData.map((item, i) => {
      if (i === index) {
        if (name === "status") {
          return { ...item, [name]: checked ? "active" : "inactive" };
        } else {
          return { ...item, [name]: value };
        }
      } else return item;
    });

    setTableData(updatedData);
  };
  const handleDialogConfirm = () => {
    AccountService.removeRoutes(isEditEnabledIndex).then((data) => {
      setIsOpen(false);
      AccountService.getAllRoutes().then((data) => {
        setTableData(data);
      });
    });
    setIsEditEnabledIndex(null);
  };
  return (
    <Grid container spacing={0} component={Paper}>
      <ConfirmationDialog
        isOpen={isOpen}
        handleConfirm={handleDialogConfirm}
        handleCancel={() => setIsOpen(false)}
      />
      <Grid container spacing={2} sx={{ m: 2, mb: 4 }}>
        <Grid item xs={12} sm={3}>
          <TextBoxField
            label="Route Name"
            name="route_name"
            value={formData.route_name}
            onChange={handleChange}
            inputProps={{ maxLength: 50 }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextBoxField
            label="Distance (in km)"
            name="distance"
            value={formData.distance}
            onChange={handleChange}
            inputProps={{
              type: "number",
              maxLength: 8,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextBoxField
            label="Fee Amount"
            name="fee_amount"
            value={formData.fee_amount}
            onChange={handleChange}
            inputProps={{
              type: "number",
              maxLength: 8,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextBoxField
            label="Driver"
            name="driver_id"
            value={formData.driver_id}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextBoxField
            label="Note"
            name="note"
            value={formData.note}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SubmitButton
            type="submit"
            variant="contained"
            label={"Add Route"}
            handleClick={handleSubmit}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <CancelButton
            type="reset"
            variant="contained"
            label={"Reset"}
            handleClick={handleResetFormField}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      {tableData ? (
        <table className={"tableList"}>
          <thead>
            <tr>
              <th>S.N.</th>
              <th style={{ width: "350px" }}>Route Name</th>
              <th>Id</th>
              <th>Distance (In KM)</th>
              <th>Fee</th>
              <th>Driver</th>
              <th>Status</th>
              <th style={{ width: "150px" }}></th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, i) => (
              <tr key={i + 2}>
                <td>
                  <div>{i}</div>
                </td>
                <td>
                  {isEditEnabledIndex === i ? (
                    <input
                      type="text"
                      name="route_name"
                      className="inputBox"
                      onChange={(e) => handleRowFieldChange(e, i)}
                      defaultValue={item.route_name}
                    />
                  ) : (
                    <div>{item.route_name}</div>
                  )}
                </td>

                <td>
                  <div>{item.id}</div>
                </td>

                <td>
                  <div>
                    {isEditEnabledIndex === i ? (
                      <input
                        type="text"
                        name="distance"
                        className="inputBox"
                        onChange={(e) => handleRowFieldChange(e, i)}
                        defaultValue={item.distance}
                      />
                    ) : (
                      <div>{item.distance}</div>
                    )}
                  </div>
                </td>
                <td>
                  <div>
                    {isEditEnabledIndex === i ? (
                      <input
                        type="text"
                        name="fee_amount"
                        className="inputBox"
                        onChange={(e) => handleRowFieldChange(e, i)}
                        defaultValue={item.fee_amount}
                      />
                    ) : (
                      <div>{item.fee_amount}</div>
                    )}
                  </div>
                </td>
                <td>
                  <div>
                    {isEditEnabledIndex === i ? (
                      <input
                        type="text"
                        name="driver_id"
                        className="inputBox"
                        onChange={(e) => handleRowFieldChange(e, i)}
                        defaultValue={item.driver_id}
                      />
                    ) : (
                      <div>{item.driver_id}</div>
                    )}
                  </div>
                </td>
                <td>
                  {/* <div>{item.status}</div> */}
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={item.status === "active" ? true : false}
                      name={"status"}
                      onChange={(e) => handleRowFieldChange(e, i)}
                    />
                    <span className="slider round"></span>
                  </label>
                </td>
                <td>
                  <div className="btn-container">
                    {isEditEnabledIndex === i ? (
                      <button
                        className="btn-item"
                        onClick={() => saveUpdatedField(item, i)}
                      >
                        {<UpdateIcon width="20" height="20" />}
                      </button>
                    ) : (
                      <button
                        className="btn-item"
                        onClick={() => handleEditClick(item, i)}
                      >
                        {<EditIcon width="20" height="20" />}
                      </button>
                    )}

                    {isEditEnabledIndex === i ? (
                      <button
                        className="btn-item"
                        onClick={() => handleCancelEdit(item, i)}
                      >
                        {<CancelIcon width="20" height="20" />}
                      </button>
                    ) : (
                      <button
                        className="btn-item"
                        onClick={() => handleDelete(item.id)}
                      >
                        {<DeleteIcon width="20" height="20" />}
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <TableSkelton />
      )}
    </Grid>
  );
}

export default AddRoutes;
