export const baseApiUrl = "http://localhost:3080/api/v1/"; //"http://localhost:3000/";
export const dateFormat = "DD-MM-YYYY";
export const upload_image_size = {
  limit: 1000,
  message: "need to update this message",
};
/**
 * 
 
export const BASE_URL = process.env.BASE_API_URL;
export const WEB_SOCKET_API_URL = process.env.SOCKET_API_URL;
export const HOST_ROOM_URL = process.env.HOST_ROOM_URL;

export const AUTH_API_ENDPOINT = "admin/login";
export const GET_ALL_LANGUAGES_API_ENDPOINT = "admin/getLanguages";
export const UPDATE_CUSTOMER_DATA = "admin/customer/update";
export const GET_GLOBALSETTING_API_ENDPOINT = "admin/getGlobalSettings";
export const UPDATE_GLOBAL_SETTINGS_DATA = "admin/updateGlobalSettings";
export const SAVE_ADD_ADMIN_API_URL = "admin/user/add";
export const GET_CUSTOMER_DATA = "admin/customer/getData";
export const GET_ADMIN_USERS_LIST = "admin/user/getData";
export const UPDATE_ADMIN_USER = "admin/user/update";
export const GET_INTERPRETERS_DATA = "admin/interpreter";
export const GET_INTERPRETERS_LIST = "interpreter/getData";
export const ADD_INTERPRETER_DATA = "interpreter/add";
export const UPDATE_INTERPRETER_DATA = "interpreter/update";
export const FORGOT_PASSWORD_API_ENDPOINT = "forgetPassword";
export const RESET_PASSWORD_API_ENDPOINT = "resetPassword";
export const HELPDOCUMENT_API_ENDPOINT = "admin/helpdocument";
export const GET_INTERPRETERS_ACTIVITY_DATA = "interpreter/getActivityData";
export const INTERPRETER_TEST_CALL_API_ENDPOINT = "admin/interpreterTestCall";
export const CALLING_API_ENDPOINT = "call";
export const INTERPRETER_PHONE_CHECK_REGEX = /^\+?\d+$/;
export const DECIMAL_CHECK_REGEX = /^\d+$/; ///^[0-9]{1,8}$/;
export const WHITESPACES_CHECK_REGEX = /^\s+$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const END_CALL_IDEAL_TIME = 30;
//api methods
export const HTTP_VERBS = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
};
//api response code
export const STATUS_CODE = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
  INVALIDTOKEN: 403,
  ERRORCODE: 500,
  INVALID_PAYLOAD: 422,
};

export const COUNTER_WAITING_LIMIT_MINUTES = 59;

export const PAGINATION_SETTING = {
  RECORDS_PER_PAGE: 5,
  ACTIVITY_TAB_PER_PAGE: 20
};

export const UPLOAD_IMAGE_SIZE = {
  LIMIT: 1000
};

export const API_VERBS = {
  YES: true,
  NO: false
}
export const HEADER = {
  YES: true,
  NO: false
};
export const NO_BODY_CONTENT = false;

//
export const deviceDisableReason = {
  DISABLED_BY_PROFILE: "DISABLED_BY_PROFILE",
  NO_PERMISSION: "NO_PERMISSION",
  NO_DEVICE: "NO_DEVICE",
  SUSPENDED: "SUSPENDED",
};

export const connectionEnum = {
  NOT_STABLE: "notstable",
  VERY_UNSTABLE: "veryunstable",
  RTT_INCREASED: "rttincreased",
  SLOW: "slow",
  GOOD: "good",
  OK: "ok",
};

export const THEME_COLOR = {
  PRIMARY_COLOR: "#2C3F6A",
  WHITE_COLOR: "#ffffff",
}





 * 
 */
