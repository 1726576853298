import React, { useEffect, useState } from "react";
import EmployeeService from "../../services/EmployeeService";

const PeopleSummery = () => {
  const [listOfStudents, setListOfStudents] = useState([]);
  useEffect(() => {
    EmployeeService.getAllEmployees().then((data) => {
      console.log("fees data", data);
      setListOfStudents(data);
    });
  }, []);

  return (
    <div>
      List of employees
      {/* <Table
        columns={StudentTabColumns}
        rowData={students}
        updateStudents={updateStudents}
        deleteStudent={deleteStudent}
      /> */}
      <table className="tableList">
        <thead>
          <tr>
            <th>Name</th>
            <th>Gender</th>
            <th>Job Title</th>
            <th>Department</th>
            <th>Contact Number</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {listOfStudents.map((data) => (
            <tr>
              <td>{data.first_name + ` ` + data.last_name}</td>
              <td>{data.gender}</td>
              <td>{data.job_title}</td>
              <td>{data.department}</td>
              <td>{data.contact_number}</td>
              <td>{data.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PeopleSummery;
