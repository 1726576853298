import React from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  styled,
} from "@mui/material";

export const SelectField = (props) => {
  const {
    label,
    name,
    value,
    required,
    fullWidth,
    onChange,
    size,
    listItems,
    nameId,
  } = props;
  return (
    <StyledFormControl fullWidth>
      <InputLabel htmlFor="selectedClass">{label}</InputLabel>
      <StyledSelectField
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        defaultValue={value}
        size={size}
        fullWidth={fullWidth}
        required={required}
      >
        {listItems?.map((item, index) => (
          <StyledMenuItem key={index} value={item?.id}>
            {item[nameId]}
          </StyledMenuItem>
        ))}
      </StyledSelectField>
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)`
  label {
    font-size: 14px;
    line-height: 11px;
  }
  li {
    font-size: 14px;
  }
`;

const StyledSelectField = styled(Select)`
  input {
    padding: 10px 5px;
    font-size: 14px;
  }
  div,
  li {
    font-size: 14px;
  }
`;
const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
`;
