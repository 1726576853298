import { Container } from "@mui/material";
import React from "react";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AttendanceSection from "../dashboard/attendance/AttandanceSection.tsx";
import SignIn from "../components/sign-in/SignIn.tsx";
import ImageUploader from "../components/uploadFile/index.tsx";
import SignUp from "../components/sign-up/SignUp.tsx";
import ListOfStudents from "../pages/students/ListOfStudents.tsx";
import AddStudentForm from "../pages/students/AddStudentForm.tsx";
import AddEmployeeForm from "../pages/employee/AddEmployeeForm.tsx";
import BulkUpload from "../pages/students/BulkUpload.tsx";
import AttendanceForm from "../pages/students/AttendanceForm.tsx";
import TeachersPage from "../pages/TeachersPage.tsx";
import ClassesPage from "../pages/ClassesPage.tsx";
import AttendanceStatus from "../pages/students/AttendanceStatus.tsx";
import AddSubjects from "../pages/Admin/AddSubjects.tsx";
import CreateClass from "../pages/Admin/CreateClass.tsx";
import CreateSection from "../pages/Admin/CreateSection.tsx";
import AddStaffDesignation from "../pages/Admin/AddStaffDesignation.tsx";
import FeeManagementSystem from "../pages/Admin/FeeManagementSystem.tsx";
import AddRoutes from "../pages/Admin/AddRoutes.tsx";
import AddAttendance from "../pages/Admin/AddAttendance.tsx";
import Report from "../components/report/report.tsx";
import PeopleSummery from "../emp/people/summary.js";

const Router = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<AttendanceSection />} />
          <Route path="/add-student" element={<AddStudentForm />} />
          <Route path="/add-student-attendance" element={<AddAttendance />} />
          <Route path="/add-attendance" element={<AttendanceForm />} />
          <Route path="/add-students-inbulk" element={<BulkUpload />} />
          <Route
            path="/students/view-attendance"
            element={<AttendanceSection />}
          />
          <Route path="/employee/add-employee/" element={<AddEmployeeForm />} />

          <Route path="/auth/signin" element={<SignIn />} />
          <Route path="/admin/add-class/" element={<CreateClass />} />
          <Route path="/admin/add-section" element={<CreateSection />} />
          <Route path="/admin/add-subject" element={<AddSubjects />} />
          <Route path="/admin/designation" element={<AddStaffDesignation />} />
          <Route path="/image/upload" element={<ImageUploader />} />
          <Route path="/auth/signup" element={<SignUp />} />
          <Route path="/student-report" element={<ListOfStudents />} />
          <Route path="/students-list" element={<ListOfStudents />} />

          <Route path="/employee/list-employee/" element={<PeopleSummery />} />

          <Route path="/teachers" element={<TeachersPage />} />
          <Route path="/classes" element={<ClassesPage />} />
          <Route path="/admin/routes" element={<AddRoutes />} />
          <Route path="/report/student/" element={<Report />} />
          <Route
            path="/admin/fee_management"
            element={<FeeManagementSystem />}
          />
        </Routes>
      </Suspense>
    </Container>
  );
};

export default Router;
