import React from "react";
import StudentIDCard from "./StudentIDCard.tsx";

const StudentIDCardContainer = () => {
  const studentData = {
    id: "01308", //14
    name: "Bhavna Singh",
    class: "V",
    section: "Arya",
    photoUrl: "https://example.com/photo.jpg", // Replace with actual photo URL
  };

  return (
    <div>
      <h1>Your Student ID Cards</h1>
      <StudentIDCard student={studentData} />
    </div>
  );
};

export default StudentIDCardContainer;
