import React from "react";
import Barcode from "react-barcode";
import AttendanceView from "./AttendanceView.tsx";

const StudentIDCard = ({ student }) => {
  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    //  window.print();
    if (printWindow) {
      printWindow.document.write(`
          <html>
            <head>
              <title>Student ID</title>
              <style>
                body {
                  font-family: 'Arial', sans-serif;
                  text-align: center;
                }
                #id {
                  font-size: 24px;
                  margin: 20px;
                }
              </style>
            </head>
            <body>
            <h2>${student.name}</h2>
            <p>Student ID: ${student.id}</p>
            <p>Class: ${student.class}</p>
            <p>Section: ${student.section}</p>
            </body>
          </html>
        `);

      printWindow.document.close();
      printWindow.print();
    } else {
      alert(
        "Popup blocker is preventing printing. Please disable it and try again."
      );
    }
  };
  return (
    <div style={styles.card}>
      <img src={student.photoUrl} alt="Student Photo" style={styles.photo} />
      <div style={styles.details}>
        <h2>{student.name}</h2>
        <p>Student ID: {student.id}</p>
        <p>Class: {student.class}</p>
        <p>Section: {student.section}</p>
      </div>
      <Barcode value={student.id} width={1} height={40} />
      <button onClick={handlePrint} style={styles.printButton}>
        Print
      </button>
    </div>
  );
};

const styles = {
  card: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    width: "300px",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  photo: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    marginBottom: "10px",
  },
  details: {
    textAlign: "center",
  },
  printButton: {
    marginTop: "10px",
    padding: "8px 16px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default StudentIDCard;
