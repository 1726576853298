// src/services/StudentService.js
import axios from "axios";
import { baseApiUrl } from "../constant";
const BASE_URL_Student = baseApiUrl + "students";
const BASE_URL_Attendance = baseApiUrl + "students/attendance";
const BASE_URL_Classes = baseApiUrl + "courses";
const BASE_URL_Class_Section = baseApiUrl + "courses/sections";
const BASE_URL_Submit_Attendance = baseApiUrl + "students/submit-attendance";

const getAllStudents = (params) => {
  let apiUrl = BASE_URL_Student;
  if (params) {
    const { class_id, section_id, date, student_id } = params;
    apiUrl += `?class_id=${class_id}&section_id=${section_id}`;
  }
  return axios.get(apiUrl).then((response) => response.data);
};

const headerObj = {
  headers: {
    // Authorization: "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
    "Content-type": "multipart/form-data",
  },
};
const addNewStudent = (params) => {
  console.log("params: ", params);
  return (
    axios
      .post("http://localhost:3080/api/v1/students/upload", params, {
        ...headerObj,
      })
      // .post("http://localhost:3080/api/v1/students/upload", params, {
      //   ...headerObj,
      // })
      .then((response) => response.data)
  );
};

const updateStudents = (params) =>
  axios
    .put(BASE_URL_Student + `/${params.id}`, params)
    .then((response) => response.data);

const deleteStudents = (id) =>
  axios.delete(BASE_URL_Student + `/${id}`).then((response) => response.data);

const updateAttendance = (params) => {
  axios
    .post(BASE_URL_Submit_Attendance, params)
    .then((response) => response.data);
};

const getAllStudentAttendance = (params) => {
  if (params) {
    let apiUrl = BASE_URL_Attendance;
    const { class_id, section_id, date } = params;
    if (date && class_id && section_id) {
      apiUrl += `?class_id=${class_id}&section_id=${section_id}&date=${date}`;
    }
    if (date && !class_id && !section_id) apiUrl += `?date=${date}`;

    return axios.get(apiUrl).then((response) => response.data);
  }
};

const getAllClasses = () =>
  axios.get(BASE_URL_Classes).then((response) => response.data);

const getAllClassSections = () =>
  axios.get(BASE_URL_Class_Section).then((response) => response.data);

const saveAttendance = (params) =>
  axios.post(BASE_URL_Attendance, params).then((response) => response.data);

export default {
  getAllStudents,
  addNewStudent,
  updateStudents,
  deleteStudents,
  getAllClasses,
  getAllClassSections,
  saveAttendance,
  getAllStudentAttendance,
  updateAttendance,
};
