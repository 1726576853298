import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

var nodeElementContainer = null;
const ConfirmationDialog = (props) => {
  useEffect(() => {
    nodeElementContainer &&
      ReactDOM.render(<Modal {...props} />, nodeElementContainer);
  });

  useEffect(() => {
    nodeElementContainer = document.createElement("div");
    document.body.appendChild(nodeElementContainer);
    const root = createRoot(nodeElementContainer);
    root.render(<Modal {...props} />);
  }, []);
  return <script />;
};

export default ConfirmationDialog;

export const Modal = (props) => {
  const { handleConfirm, handleCancel, isOpen } = props;
  return (
    <div>
      {isOpen && (
        <div>
          <div className="shadow"> </div>
          <div className="confirmation-alert">
            <h1>Are you sure?</h1>
            <p>This action can't be undone.</p>
            <button onClick={handleConfirm}>Confirm</button>
            <button onClick={handleCancel}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};
