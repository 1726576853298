import axios from "axios";
import { baseApiUrl } from "../constant";

/* CURD for sections start */
const addSections = (params) =>
  axios
    .post(baseApiUrl + "courses/addSections", params)
    .then((response) => response.data);

const removeSections = (id) =>
  axios
    .delete(baseApiUrl + `courses/removeSections/${id}`)
    .then((response) => response.data);

const updateSections = (params) => {
  return axios
    .put(baseApiUrl + `courses/updateSections/${params.id}`, params)
    .then((response) => response.data);
};
/* sections ended*/

/* CURD for classes start */
const addClasses = (params) =>
  axios
    .post(baseApiUrl + "courses/addClasses", params)
    .then((response) => response.data);

const removeClasses = (id) =>
  axios
    .delete(baseApiUrl + `courses/removeClasses/${id}`)
    .then((response) => response.data);

const updateClasses = (params) => {
  return axios
    .put(baseApiUrl + `courses/updateClasses/${params.id}`, params)
    .then((response) => response.data);
};
/* classes ended*/

/* CURD for subject start*/

const getAllSubjects = () =>
  axios
    .get(baseApiUrl + "settings/getSubject")
    .then((response) => response.data);

const addSubject = (params) =>
  axios
    .post(baseApiUrl + "settings/addSubject", params)
    .then((response) => response.data);

const removeSubject = (id) =>
  axios
    .delete(baseApiUrl + `settings/removeSubject/${id}`)
    .then((response) => response.data);

const updateSubject = (params) => {
  return axios
    .put(baseApiUrl + `settings/updateSubject/${params.id}`, params)
    .then((response) => response.data);
};

//
/* CURD for subject start*/

const getAllDesignation = () =>
  axios
    .get(baseApiUrl + "settings/getDesignation")
    .then((response) => response.data);

const addDesignation = (params) =>
  axios
    .post(baseApiUrl + "settings/addDesignation", params)
    .then((response) => response.data);

const removeDesignation = (id) =>
  axios
    .delete(baseApiUrl + `settings/removeDesignation/${id}`)
    .then((response) => response.data);

const updateDesignation = (params) => {
  return axios
    .put(baseApiUrl + `settings/updateDesignation/${params.id}`, params)
    .then((response) => response.data);
};

//

const getAllRoutes = () =>
  axios
    .get(baseApiUrl + "settings/getRoutes")
    .then((response) => response.data);

const addRoutes = (params) =>
  axios
    .post(baseApiUrl + "settings/addRoutes", params)
    .then((response) => response.data);

const removeRoutes = (id) =>
  axios
    .delete(baseApiUrl + `settings/removeRoutes/${id}`)
    .then((response) => response.data);

const updateRoutes = (params) => {
  return axios
    .put(baseApiUrl + `settings/updateRoutes/${params.id}`, params)
    .then((response) => response.data);
};

//

const getStudentFees = (params) =>
  axios
    .get(baseApiUrl + `settings/getStudentFees?id=${params}`)
    .then((response) => response.data);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getStudentFees,
  //
  getAllRoutes,
  addRoutes,
  removeRoutes,
  updateRoutes,
  //
  addClasses,
  removeClasses,
  updateClasses,
  //
  addSections,
  removeSections,
  updateSections,
  //
  getAllSubjects, //
  addSubject,
  removeSubject,
  updateSubject,
  //
  getAllDesignation,
  addDesignation,
  removeDesignation,
  updateDesignation,
};
