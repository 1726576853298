import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Typography,
  FormControl,
  Snackbar,
  Alert,
  Paper,
} from "@mui/material";
import StudentService from "../../services/StudentService.js";
import {
  SubmitButton,
  CancelButton,
} from "../../components/common/Buttons.tsx";
import { TextBoxField } from "../../components/common/TextField.tsx";
import { SelectField } from "../../components/common/Select.tsx";
import { RadioGroupField } from "../../components/common/RadioGroup.tsx";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ImageUploader from "../../components/common/ImageUploader.tsx";
import { upload_image_size } from "../../constant.js";

const steps = ["Basic", "Salary", "Preview Offer", "Send Email"];

const bloodGroup = [
  { id: 1, value: "A +ve" },
  { id: 2, value: "A -ve" },
  { id: 3, value: "B +ve" },
  { id: 4, value: "B -ve" },
  { id: 5, value: "O +ve" },
  { id: 6, value: "O -ve" },
  { id: 7, value: "AB +ve" },
  { id: 8, value: "AB -ve" },
];

//Personal Details
// Address
//Demography
//Fees Payment

const defaultFormData = {
  first_name: "",
  last_name: "",
  blood_group: "",
  date_of_birth: "",
  gender: "male",
  mother_name: "",
  father_name: "",
  application_date: "",
  admission_date: "",
  class_id: "",
  section_id: "",
  email: "",
  mobile: "",
  address: "",
  note: "",
  profile_image: "",
  date_of_joining: "",
};
const AddEmployeeForm = () => {
  const [classes, setClasses] = useState([]);
  const [classesSections, setClassesSections] = useState([]);
  const [formData, setFormData] = useState({ ...defaultFormData });
  const [showAlert, setShowAlert] = useState(false);
  const [empImage, setEmpImage] = useState();
  const [stepLabel, setStepLabel] = useState(1);

  useEffect(() => {
    StudentService.getAllClasses().then((data) => {
      setClasses(data);
    });

    StudentService.getAllClassSections().then((data) => {
      setClassesSections(data);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      first_name,
      last_name,
      date_of_birth,
      gender,
      mother_name,
      father_name,
      admission_date,
      class_id,
      section_id,
      email,
      mobile,
      address,
      note,
      profile_image,
      date_of_joining,
    } = formData;
    const formDataToSend = new FormData();
    formDataToSend.append("first_name", first_name);
    formDataToSend.append("last_name", last_name);
    formDataToSend.append("date_of_birth", date_of_birth);
    formDataToSend.append("gender", gender);
    formDataToSend.append("mother_name", mother_name);
    formDataToSend.append("father_name", father_name);
    formDataToSend.append("admission_date", admission_date);
    formDataToSend.append("class_id", class_id);
    formDataToSend.append("section_id", section_id);
    formDataToSend.append("email", email);
    formDataToSend.append("mobile", mobile);
    formDataToSend.append("address", address);
    formDataToSend.append("note", note);
    formDataToSend.append("profile_image", profile_image);
    formDataToSend.append("file", profile_image);
    StudentService.addNewStudent(formDataToSend).then((response) => {
      console.log("form api resposne file", response);
      setShowAlert(true);
    });
  };
  const handleClose = () => {
    setShowAlert(false);
  };

  const uploadProfileImage = (filesData, file, event) => {
    if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg"
    ) {
      if (filesData) {
        setFormData((prevData) => ({
          ...prevData,
          profile_image: filesData,
        }));
        setEmpImage(filesData);
      }
    } else {
      console.error("profile image error");
    }
  };

  return (
    <Container sx={{ p: 5 }} component={Paper}>
      <Typography variant="h4" align="center" gutterBottom>
        <Stepper activeStep={stepLabel} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Typography>
      {showAlert && (
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Student added successfully!
          </Alert>
        </Snackbar>
      )}
      <button>Back</button>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <Grid container spacing={2}>
          {stepLabel === 0 && (
            <>
              <Grid item xs={12} sm={6}>
                <TextBoxField
                  label="First Name"
                  name="first_name"
                  value={formData.first_name}
                  inputProps={{ maxLength: 100 }}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextBoxField
                  label="Last Name"
                  name="last_name"
                  value={formData.last_name}
                  inputProps={{ maxLength: 100 }}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextBoxField
                  id="outlined-required"
                  label="Date of Birth"
                  type="date"
                  name="date_of_birth"
                  value={formData.date_of_birth || new Date()}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <SelectField
                  label="Blood Group"
                  name="blood_group"
                  nameId="value"
                  selectvalue={formData?.blood_group}
                  onChange={handleChange}
                  listItems={bloodGroup}
                  size="small"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextBoxField
                  variant="outlined"
                  required
                  fullWidth
                  type="email"
                  label="Aadhar Number"
                  name="aadhar"
                  defaultValue={formData?.aadhar}
                  onChange={handleChange}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  variant="outlined"
                  required
                  fullWidth
                  type="email"
                  label="Email Address"
                  name="email"
                  defaultValue={formData.email}
                  onChange={handleChange}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  variant="outlined"
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  defaultValue={""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 11 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextBoxField
                  label="Mother Name"
                  name="mother_name"
                  value={formData.mother_name}
                  onChange={handleChange}
                  inputProps={{ maxLength: 200 }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextBoxField
                  label="Father Name"
                  name="father_name"
                  value={formData.father_name}
                  onChange={handleChange}
                  inputProps={{ maxLength: 200 }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextBoxField
                  label="Date of joining"
                  type="date"
                  name="hire_date"
                  value={formData?.date_of_joining || new Date()}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextBoxField
                  label="Job Title"
                  name="job_title"
                  value={formData?.job_title}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid item xs={12} sm={6}>
                  <TextBoxField
                    label="Citizenship"
                    name="citizenship"
                    value={formData?.citizenship}
                    onChange={handleChange}
                    style={{ marginRight: "-2px" }}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextBoxField
                  label="Designation"
                  name="designation"
                  value={formData?.designation}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextBoxField
                  label="Department"
                  name="job_title"
                  value={formData?.job_title}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextBoxField
                  label="Reporting Manager"
                  name="job_title"
                  value={formData?.manager}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset" size="small">
                  <RadioGroupField
                    row
                    label="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    defaultValue={"male"}
                  />

                  {empImage && (
                    <div>
                      <img
                        srcSet={empImage}
                        src={`data:image/jpeg;base64,${empImage}`}
                        alt={"user profile"}
                        loading="lazy"
                        width={200}
                        height={200}
                        style={{ border: "1px solid #000", margin: "4px 0" }}
                      />
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <label>Photograph</label>
                  <ImageUploader
                    limitSize={upload_image_size.limit}
                    message={upload_image_size.message}
                    id="StudentProfileImage"
                    name="profile_image"
                    getImageFile={uploadProfileImage}
                  />
                </div>
              </Grid>

              <Grid item xs={4}>
                <TextBoxField
                  variant="outlined"
                  required
                  fullWidth
                  label="Line 1"
                  name="address"
                  defaultValue={""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 350 }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextBoxField
                  variant="outlined"
                  required
                  fullWidth
                  label="Line 2"
                  name="address"
                  defaultValue={""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 350 }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextBoxField
                  variant="outlined"
                  required
                  fullWidth
                  label="City"
                  name="address"
                  defaultValue={""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 350 }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextBoxField
                  variant="outlined"
                  required
                  fullWidth
                  label="State"
                  name="address"
                  defaultValue={""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 350 }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextBoxField
                  variant="outlined"
                  required
                  fullWidth
                  label="Zip"
                  name="address"
                  defaultValue={""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 350 }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextBoxField
                  variant="outlined"
                  required
                  fullWidth
                  label="Country"
                  name="address"
                  defaultValue={""}
                  onChange={handleChange}
                  inputProps={{ maxLength: 350 }}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <SubmitButton
                  type="submit"
                  variant="contained"
                  label={"Submit"}
                />
              </Grid>
            </>
          )}

          {stepLabel === 1 && (
            <Grid item xs={12}>
              <table className={"tableList"}>
                <tbody>
                  <tr>
                    <td>Is Provident Fund (PF) applicable?</td>
                    <td>
                      <label className="switch">
                        <input
                          type="checkbox"
                          value={"true"}
                          name={"status"}
                          onClick={(e) => handleChange}
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Is Employee Pension Scheme (EPS) applicable?</td>
                    <td>
                      <label className="switch">
                        <input
                          type="checkbox"
                          value={"true"}
                          name={"status"}
                          onClick={(e) => handleChange}
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Is Employee State Insurance (ESI) applicable?</td>
                    <td>
                      <label className="switch">
                        <input
                          type="checkbox"
                          value={"true"}
                          name={"status"}
                          onClick={(e) => handleChange}
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Is Labour Welfare Fund (LWF) applicable?</td>
                    <td>
                      <label className="switch">
                        <input
                          type="checkbox"
                          value={"true"}
                          name={"status"}
                          onClick={(e) => handleChange}
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Is Professional Tax (PT) applicable?</td>
                    <td>
                      <label className="switch">
                        <input
                          type="checkbox"
                          value={"true"}
                          name={"status"}
                          onClick={(e) => handleChange}
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Basic Salary CTC:</td>
                    <td>
                      <TextBoxField
                        variant="outlined"
                        required
                        fullWidth
                        label="Country"
                        name="address"
                        defaultValue={""}
                        onChange={handleChange}
                        inputProps={{ maxLength: 350 }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Enter Salary Data (Per Annum) * You can also add optional
                      salary components and custom salary components. Note that
                      both percentage and absolute value of Basic and HRA can be
                      edited.
                      <table>
                        <tr>
                          <td>CTC</td>
                          <td>
                            <input type="text" />
                          </td>
                        </tr>
                        <tr>
                          <td>Basic (% of CTC)</td>
                          <td>
                            <input type="text" />
                          </td>
                          <td>Basic</td>
                          <td>
                            <input type="text" />
                          </td>
                        </tr>
                        <tr>
                          <td>HRA (% of Basic)</td>
                          <td>
                            <input type="text" />
                          </td>
                          <td>HRA </td>
                          <td>
                            <input type="text" />
                          </td>
                        </tr>
                        <tr>
                          <td>PF-Employer Contribution</td>
                          <td>
                            <input type="text" />
                          </td>
                        </tr>
                        <tr>
                          <td>Special Allowance </td>
                          <td>
                            <input type="text" />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default AddEmployeeForm;
