// src/pages/StudentsPage.js
import React, { useState, useEffect } from "react";
import StudentService from "../services/StudentService";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

const ClassesPage = () => {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    // Fetch students from the backend
    StudentService.getAllStudents().then((data) => {
      setStudents(data);
    });
  }, []);

  return (
    <div>
      <h2>Students Page</h2>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </div>
    </div>
  );
};

export default ClassesPage;
