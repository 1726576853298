import React from "react";
import { Button, styled } from "@mui/material";

export const SubmitButton = (props) => {
  const { label, name, handleClick, type } = props;
  return (
    <StyledSubmitButton
      fullWidth
      variant="contained"
      onClick={handleClick}
      name={name}
      type={type}
      size="small"
    >
      {label}
    </StyledSubmitButton>
  );
};

const StyledSubmitButton = styled(Button)`
  background-color: #6ba36b;
  max-width: max-content;
  padding: 5px 20px;
  margin: 0;
  font-size: 1rem;
  &:hover {
    background-color: #548554;
  }
`;

export const CancelButton = (props) => {
  const { label, name, handleClick, type } = props;
  return (
    <StyledCancelButton
      fullWidth
      variant="contained"
      onClick={handleClick}
      name={name}
      type={type}
    >
      {label}
    </StyledCancelButton>
  );
};

const StyledCancelButton = styled(Button)`
  background-color: #934545;
  max-width: max-content;
  padding: 5px 20px;
  margin: 0;
  font-size: 1rem;
  max-width: max-content;
  &:hover {
    background-color: #762727;
  }
`;

export const AttendanceButton = (props) => {
  const { label, name, handleClick, type, color, disable } = props;
  return (
    <StyledAttendanceButton
      fullWidth
      variant="contained"
      onClick={handleClick}
      name={name}
      type={type}
      size="small"
      disable={disable}
      style={{
        backgroundColor: color,
        opacity: disable ? 0.5 : 1,
        cursor: disable ? "no-drop" : "pointer",
      }}
    >
      {label}
    </StyledAttendanceButton>
  );
};

const StyledAttendanceButton = styled(Button)`
  background-color: green;
  max-width: max-content;
  padding: 6px 15px;
  margin: 5px;
  flex-grew: 1;
  flex: 1 1 0px;
  font-size: 1rem;
  min-width: auto;
  border-radius: 20px;
  &:hover {
    background-color: #97d797;
  }
`;
