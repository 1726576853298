// src/pages/StudentsPage.js
import React, { useState, useEffect } from "react";
import StudentService from "../../services/StudentService.js";
import { StudentTabColumns } from "./utils.ts";
import Table from "./StudentDataTable2.tsx";
import { SelectField } from "../../components/common/Select.tsx";
import { Grid, Paper } from "@mui/material";

const StudentsPage = () => {
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classesSections, setClassesSections] = useState([]);
  const [formFilterData, setFormFilterData] = useState([
    {
      class_id: "",
      section_id: "",
    },
  ]);

  useEffect(() => {
    // Fetch students from the backend
    StudentService.getAllStudents().then((data) => {
      setStudents(data);
    });

    // Fetch students from the backend
    StudentService.getAllClasses().then((data) => {
      console.log("data: ", data);
      if (data) {
        let classesData = [];
        classesData.push(data);
        console.log("data classesData1: ", classesData);
        [...classesData].push({
          id: 0,
          class_name: "Select Al",
          class_code: "all",
        });
        console.log("data classesData: ", classesData);
        setClasses(classesData);
      }
    });

    StudentService.getAllClassSections().then((data) => {
      setClassesSections(data);
    });
  }, []);

  useEffect(() => {
    const { class_id, section_id } = formFilterData;
    if (class_id && section_id) getStudentsList(formFilterData);
  }, [formFilterData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFilterData((prevData) => ({ ...prevData, [name]: value }));
  };

  const deleteStudent = (id: number) => {
    StudentService.deleteStudents(id);
    StudentService.getAllStudents().then((data) => {
      setStudents(data);
    });
  };

  const updateStudents = (params) => {
    console.log("updateStudents params: ", params);
    StudentService.updateStudents(params).then((data) => {
      StudentService.getAllStudents().then((data) => {
        setStudents(data);
      });
    });
  };

  const getStudentsList = (params) => {
    StudentService.getAllStudents(params).then((students) => {
      const stuList = [];
      students.map((stu) => {
        stuList.push({ student_id: stu.id, isPresent: false });
      });
      setStudents(students);
    });
  };

  return (
    <Grid container spacing={2} component={Paper}>
      <Grid item xs={2} lg={2}>
        <h3> Filter Students By:</h3>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 3, ml: 0.1 }}>
        <Grid item xs={5} lg={5}>
          <SelectField
            label="Class"
            name="class_id"
            nameId="class_name"
            onChange={handleChange}
            listItems={classes}
            size="small"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={5} lg={5}>
          <SelectField
            label="Section"
            name="section_id"
            nameId="section_name"
            onChange={handleChange}
            listItems={classesSections}
            size="small"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Table
        columns={StudentTabColumns}
        rowData={students}
        updateStudents={updateStudents}
        deleteStudent={deleteStudent}
      />
    </Grid>
  );
};

export default StudentsPage;
