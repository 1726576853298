import React, { useState, useEffect } from "react";
import { TextField, Button, Grid, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom"; // If you're using React Router for navigation
import {
  SubmitButton,
  CancelButton,
} from "../../components/common/Buttons.tsx";
import { TextBoxField } from "../../components/common/TextField.tsx";
import { SelectField } from "../../components/common/Select.tsx";
import { RadioGroupField } from "../../components/common/RadioGroup.tsx";
import ImageUploader from "../../components/common/ImageUploader.tsx";
import { upload_image_size } from "../../constant.js";
import AccountService from "../../services/AccountService.js";

interface editStudentsProps {
  closeDrawer: (arg: boolean) => void;
  selectedFormData: Object;
  updateStudents: (arg: Object) => void;
}

const EditStudentForm = (props: editStudentsProps) => {
  const { closeDrawer, selectedFormData, updateStudents } = props;
  console.log("selectedFormData props: ", selectedFormData);
  const [formData, setFormData] = React.useState(selectedFormData);
  const [routeData, setRouteData] = React.useState([]);
  const [studentImage, setStudentImage] = useState(formData?.profile_image);

  useEffect(() => {
    AccountService.getAllRoutes().then((data) => {
      setRouteData(data);
    });
  }, []);

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const uploadProfileImage = (filesData, file, event) => {
    if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg"
    ) {
      if (filesData) {
        setFormData((prevData) => ({
          ...prevData,
          profile_image: filesData,
        }));
        setStudentImage(filesData);
      }
    } else {
      console.error("profile image error");
    }
  };

  const handleSubmit = (event) => {
    console.log("formData: ", formData);
    event.preventDefault();
    setFormData(formData);
    updateStudents(formData);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh", width: "100%" }}
    >
      <Grid item xs={12} sm={16} md={12}>
        <Paper elevation={0} style={{ padding: 16 }}>
          <Typography variant="h5" gutterBottom>
            Edit Student
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextBoxField
                  label="First Name"
                  fullWidth
                  value={formData.first_name}
                  onChange={(e) =>
                    handleInputChange("first_name", e.target.value)
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Last Name"
                  fullWidth
                  value={formData.last_name}
                  onChange={(e) =>
                    handleInputChange("last_name", e.target.value)
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <div>
                  {studentImage && (
                    <div>
                      <img
                        src={`data:image/jpeg;base64,${studentImage}`}
                        alt={"user profile"}
                        loading="lazy"
                        width={50}
                        height={50}
                        style={{ border: "1px solid #000", margin: "4px 0" }}
                      />
                    </div>
                  )}
                  <label>Photograph</label>
                  <ImageUploader
                    limitSize={upload_image_size.limit}
                    message={upload_image_size.message}
                    id="StudentProfileImage"
                    name="profile_image"
                    getImageFile={uploadProfileImage}
                  />
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <SelectField
                  label="Pick Drop Routs Point"
                  name="route"
                  nameId="route_name"
                  selectvalue={formData.route ?? "none"}
                  onChange={handleInputChange}
                  listItems={routeData}
                  size="small"
                  fullWidth
                  // required
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Email"
                  fullWidth
                  value={formData.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Date of Birth"
                  fullWidth
                  value={formData.date_of_birth}
                  onChange={(e) =>
                    handleInputChange("date_of_birth", e.target.value)
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Father Name"
                  fullWidth
                  value={formData.father_name}
                  onChange={(e) =>
                    handleInputChange("father_name", e.target.value)
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Mother Name"
                  fullWidth
                  value={formData.mother_name}
                  onChange={(e) =>
                    handleInputChange("mother_name", e.target.value)
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Mobile"
                  fullWidth
                  value={formData.mobile}
                  onChange={(e) => handleInputChange("mobile", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Address"
                  fullWidth
                  value={formData.address}
                  onChange={(e) => handleInputChange("address", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Mobile"
                  fullWidth
                  value={formData.mobile}
                  onChange={(e) => handleInputChange("mobile", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Address"
                  fullWidth
                  value={formData.address}
                  onChange={(e) => handleInputChange("address", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Mobile"
                  fullWidth
                  value={formData.mobile}
                  onChange={(e) => handleInputChange("mobile", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Address"
                  fullWidth
                  value={formData.address}
                  onChange={(e) => handleInputChange("address", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Mobile"
                  fullWidth
                  value={formData.mobile}
                  onChange={(e) => handleInputChange("mobile", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Address"
                  fullWidth
                  value={formData.address}
                  onChange={(e) => handleInputChange("address", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Mobile"
                  fullWidth
                  value={formData.mobile}
                  onChange={(e) => handleInputChange("mobile", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Address"
                  fullWidth
                  value={formData.address}
                  onChange={(e) => handleInputChange("address", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Mobile"
                  fullWidth
                  value={formData.mobile}
                  onChange={(e) => handleInputChange("mobile", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextBoxField
                  label="Address"
                  fullWidth
                  value={formData.address}
                  onChange={(e) => handleInputChange("address", e.target.value)}
                  margin="normal"
                />
              </Grid>
              {/* Add other fields as needed */}
              <Grid item xs={6}>
                <SubmitButton
                  label="Save"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 16 }}
                  onClick={handleSubmit}
                />
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 16 }}
                  onClick={closeDrawer(false)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EditStudentForm;
