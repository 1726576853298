import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ExpandMore } from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Collapse, styled } from "@mui/material";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import People from "@mui/icons-material/People";
import { Link } from "react-router-dom";
import { ReactComponent as AdminSettingIcon } from "../assets/svgIcons/admin_setting.svg";
import { ReactComponent as StuAdminSettingIcon } from "../assets/svgIcons/stu_admin.svg";
import { ReactComponent as StudentMngIcon } from "../assets/svgIcons/student.svg";
import { ReactComponent as StaffMngIcon } from "../assets/svgIcons/staff_admin.svg";
import { ReactComponent as AttendanceMngIcon } from "../assets/svgIcons/attendance_admin.svg";
export const MainListItems = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string | false) => {
    setExpanded(panel ? panel : false);
  };

  return (
    <React.Fragment>
      <ListItemButton
        onClick={() =>
          handleChange(
            expanded === "admin_master_mng" ? false : "admin_master_mng"
          )
        }
      >
        <StyledIcon>
          <AdminSettingIcon width="40" height="40" fill={"red"} />
        </StyledIcon>
        <ListItemText primary="Admin Master" />
        <ExpandMore />
      </ListItemButton>
      <div
        style={{
          backgroundColor: "rgba(211,211,211,0.4)",
        }}
      >
        <Collapse in={expanded === "admin_master_mng"} timeout="auto">
          <StyledLink to="admin/add-class/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Classes" />
            </ListItemButton>
          </StyledLink>
          <StyledLink to="admin/add-section/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Sections" />
            </ListItemButton>
          </StyledLink>
          <StyledLink to="admin/add-subject/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Subjects" />
            </ListItemButton>
          </StyledLink>
          <StyledLink to="admin/designation/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Staff Designation" />
            </ListItemButton>
          </StyledLink>
          {/* <StyledLink to="admin/inquiry">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Admission Inquiries" />
            </ListItemButton>
          </StyledLink> */}
          <StyledLink to="admin/routes/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Manage Routes" />
            </ListItemButton>
          </StyledLink>
          <StyledLink to="admin/fee-management/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Fee Management System" />
            </ListItemButton>
          </StyledLink>
          <StyledLink to="admin/print-admission-form/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Print Admission Forms" />
            </ListItemButton>
          </StyledLink>
        </Collapse>
      </div>
      {/* <ListItemButton
        onClick={() =>
          handleChange(expanded === "admission_mng" ? false : "admission_mng")
        }
      >
        <StyledIcon>
          <StuAdminSettingIcon width="30" height="30" fill={"red"} />
        </StyledIcon>
        <ListItemText primary="Admin Parents" />
        <ExpandMore />
      </ListItemButton> */}
      {/* <div
        style={{
          backgroundColor: "rgba(211,211,211,0.4)",
        }}
      >
        <Collapse in={expanded === "admission_mng"} timeout="auto">
          <StyledLink to="addstudent">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Admit Student" />
            </ListItemButton>
          </StyledLink>
          <StyledLink to="addstudent">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Adminssion Requests" />
            </ListItemButton>
          </StyledLink>
          <StyledLink to="addstudent">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Admission Inquiries" />
            </ListItemButton>
          </StyledLink>
          <StyledLink to="addstudent">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Print Admission Forms" />
            </ListItemButton>
          </StyledLink>
        </Collapse>
      </div> */}
      <ListItemButton
        onClick={() =>
          handleChange(expanded === "student_mng" ? false : "student_mng")
        }
      >
        <StyledIcon>
          <StudentMngIcon width="30" height="30" fill={"red"} />
        </StyledIcon>
        <ListItemText primary="Students Management" />
        <ExpandMore />
      </ListItemButton>
      <div
        style={{
          backgroundColor: "rgba(211,211,211,0.4)",
        }}
      >
        <Collapse in={expanded === "student_mng"} timeout="auto" unmountOnExit>
          <StyledLink to="/students-list/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="List Of Students" />
            </ListItemButton>
          </StyledLink>

          <StyledLink to="/add-student/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Add New Students" />
            </ListItemButton>
          </StyledLink>
          <StyledLink to="/add-students-inbulk/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Upload Students by CSV" />
            </ListItemButton>
          </StyledLink>
          <StyledLink to="/add-student-attendance/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Manage Student Attandence" />
            </ListItemButton>
          </StyledLink>
          <StyledLink to="/students/view-attendance/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="View Attandence" />
            </ListItemButton>
          </StyledLink>
        </Collapse>
      </div>
      <ListItemButton
        onClick={() =>
          handleChange(expanded === "staff_mng" ? false : "staff_mng")
        }
      >
        <StyledIcon>
          <StaffMngIcon width={"35"} height={"40"} />
        </StyledIcon>
        <ListItemText primary="Staff Management" />
        <ExpandMore />
      </ListItemButton>
      <div
        style={{
          backgroundColor: "rgba(211,211,211,0.4)",
        }}
      >
        <Collapse in={expanded === "staff_mng"} timeout="auto" unmountOnExit>
          <StyledLink to="/employee/add-employee/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Add new employee" />
            </ListItemButton>
          </StyledLink>

          <StyledLink to="employee/list-employee/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="List of employee" />
            </ListItemButton>
          </StyledLink>

          <StyledLink to="employee/view-attendance/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="View Attandence of employee" />
            </ListItemButton>
          </StyledLink>
        </Collapse>
      </div>
      <ListItemButton
        onClick={() =>
          handleChange(expanded === "attendance_mng" ? false : "attendance_mng")
        }
      >
        <StyledIcon>
          <AttendanceMngIcon width={"30"} height={"36"} />
        </StyledIcon>
        <ListItemText primary="Attendance Management" />
        <ExpandMore />
      </ListItemButton>
      <div
        style={{
          backgroundColor: "rgba(211,211,211,0.4)",
        }}
      >
        <Collapse
          in={expanded === "attendance_mng"}
          timeout="auto"
          unmountOnExit
        >
          <StyledLink to="/add-student-attendance/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Student Attendance" />
            </ListItemButton>
          </StyledLink>

          <StyledLink to="/add-employee/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Employee Attendance" />
            </ListItemButton>
          </StyledLink>
          <StyledLink to="/view-attendance/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Leave Management" />
            </ListItemButton>
          </StyledLink>
        </Collapse>
      </div>

      <ListItemButton
        onClick={() =>
          handleChange(expanded === "fees_mng" ? false : "fees_mng")
        }
      >
        <StyledIcon>
          <People />
        </StyledIcon>
        <ListItemText primary="Fees Management" />
        <ExpandMore />
      </ListItemButton>
      <div
        style={{
          backgroundColor: "rgba(211,211,211,0.4)",
        }}
      >
        <Collapse in={expanded === "fees_mng"} timeout="auto" unmountOnExit>
          <StyledLink to="/fees">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Add Fees Type" />
            </ListItemButton>
          </StyledLink>

          <StyledLink to="/add-student">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Fees Structure" />
            </ListItemButton>
          </StyledLink>
        </Collapse>
      </div>
      <ListItemButton
        onClick={() =>
          handleChange(expanded === "fees_mng" ? false : "fees_mng")
        }
      >
        <StyledIcon>
          <People />
        </StyledIcon>
        <ListItemText primary="Exam Management" />
        <ExpandMore />
      </ListItemButton>
      <div
        style={{
          backgroundColor: "rgba(211,211,211,0.4)",
        }}
      >
        <Collapse in={expanded === "fees_mng"} timeout="auto" unmountOnExit>
          <StyledLink to="/fees">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Results" />
            </ListItemButton>
          </StyledLink>

          <StyledLink to="/add-student/">
            <ListItemButton>
              <StyledIcon>
                <NavigateNextRoundedIcon />
              </StyledIcon>
              <ListItemText primary="Fees Structure" />
            </ListItemButton>
          </StyledLink>
        </Collapse>
      </div>

      <ListItemButton>
        <StyledIcon>
          <PeopleIcon />
        </StyledIcon>
        <ListItemText primary="Fees Collection" />
      </ListItemButton>

      <ListItemButton>
        <StyledIcon>
          <LayersIcon />
        </StyledIcon>
        <ListItemText primary="ID Card Printing" />
      </ListItemButton>
      <ListItemButton>
        <StyledIcon>
          <ShoppingCartIcon />
        </StyledIcon>
        <ListItemText primary="Accountants" />
      </ListItemButton>
      <ListItemButton>
        <StyledIcon>
          <LayersIcon />
        </StyledIcon>
        <ListItemText primary="Public Messages" />
      </ListItemButton>
      <ListItemButton>
        <StyledIcon>
          <LayersIcon />
        </StyledIcon>
        <ListItemText primary="Manage Attandance" />
      </ListItemButton>
      <ListItemButton>
        <StyledIcon>
          <LayersIcon />
        </StyledIcon>
        <ListItemText primary="Online Classes" />
      </ListItemButton>
      <ListItemButton>
        <StyledIcon>
          <LayersIcon />
        </StyledIcon>
        <ListItemText primary="Timetable Management" />
      </ListItemButton>
      <ListItemButton>
        <StyledIcon>
          <LayersIcon />
        </StyledIcon>
        <ListItemText primary="Fee Payment" />
      </ListItemButton>
    </React.Fragment>
  );
};

const StyledIcon = styled(ListItemIcon)`
  svg {
    color: #ffffff !important;
    fill: white;
  }
`;

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <StyledIcon>
        <AssignmentIcon />
      </StyledIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <StyledIcon>
        <AssignmentIcon />
      </StyledIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <StyledIcon>
        <AssignmentIcon />
      </StyledIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #0000008a;
  font-size: 14px;
`;
