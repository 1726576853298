import React, { useEffect, useState } from "react";
import DataChart from "../../components/Chart.tsx";
import moment from "moment";
import StudentService from "../../services/StudentService";
import { dateFormat } from "../../constant.js";
import { Grid, TextField } from "@mui/material";

export const data = [
  ["Present", "Absent"],
  ["Present", 5000],
  ["Absent", 220],
];

export const options = {
  title: "Student Attendance",
};

const AttendanceSection = () => {
  const [studentChartData, setStudentChartData] = useState(data);
  const [chartOptions, setChartOptions] = useState(options);
  const [selectedDate, setSelectedDate] = useState<null | Date>(new Date());
  const [isHoliday, setIsHoliday] = useState(false);

  useEffect(() => {
    const filterData = {
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    StudentService.getAllStudentAttendance(filterData).then((data) => {
      setIsHoliday(data.is_holiday);
      if (data.data.length == 0) {
        const updatedData = [
          ["Present", "Absent"],
          ["Present", 0],
          ["Absent", 6],
        ];
        setChartOptions({
          ...chartOptions,
          title: `Total Sudents: Attendance not available`,
        });
        setStudentChartData(updatedData);
      } else {
        const presentStudents = data.data.filter((item) => item.is_present);
        const absentStudents = data.data.filter((item) => !item.is_present);

        const updatedData = [
          ["Present", "Absent"],
          ["Present", presentStudents.length],
          ["Absent", absentStudents.length],
        ];
        setChartOptions({
          ...chartOptions,
          title: `Total Sudents: ${data.data.length}`,
        });
        setStudentChartData(updatedData);
      }
    });
  }, [selectedDate]);

  return (
    <div>
      <h1>Students Attendance Report {isHoliday && " - Holiday"}</h1>

      <Grid item xs={3}>
        <TextField
          label="Select Date"
          type="date"
          name="date"
          defaultValue={selectedDate}
          value={moment(selectedDate).format("YYYY-MM-DD")}
          onChange={(e) => setSelectedDate(e.target.value)}
          fullWidth
          required
        />
      </Grid>

      {studentChartData && chartOptions && (
        <DataChart
          studentChartData={studentChartData}
          chartOptions={chartOptions}
        />
      )}
    </div>
  );
};

export default AttendanceSection;
