import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import EditStudentForm from "../../pages/students/EditStudentForm.tsx";

interface DrawerContainerProps {
  linkText: string;
  selectedRecord: Object;
  updateStudents: (arg: Object) => void;
}
export default function DrawerContainer(props: DrawerContainerProps) {
  const { linkText, selectedRecord, updateStudents } = props;
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [formData, setFormData] = React.useState({ ...selectedRecord });
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpenDrawer(!openDrawer);
    };

  React.useEffect(() => {
    setFormData(selectedRecord);
  }, [selectedRecord]);

  const list = () => (
    <Box
      sx={{ width: 650 }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      {formData && (
        <EditStudentForm
          selectedFormData={formData}
          closeDrawer={toggleDrawer}
          updateStudents={updateStudents}
        />
      )}
    </Box>
  );

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(true)}>{linkText}</Button>
          <Drawer
            anchor={anchor}
            open={openDrawer}
            onClose={toggleDrawer(false)}
          >
            {list()}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
