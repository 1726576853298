import React from "react";
import {
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel,
  styled,
} from "@mui/material";

export const RadioGroupField = (props) => {
  const { label, name, value, onChange, defaultValue } = props;
  return (
    <div>
      <StyledFormLabel component="legend">Gender</StyledFormLabel>
      <StyledRadioGroup
        row
        aria-label={label}
        name={name}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
      >
        <FormControlLabel value="male" control={<Radio />} label="Male" />
        <FormControlLabel value="female" control={<Radio />} label="Female" />
        <FormControlLabel value="other" control={<Radio />} label="Other" />
        {/* Add more options if needed */}
      </StyledRadioGroup>
    </div>
  );
};
const StyledFormLabel = styled(FormLabel)`
  font-size: 14px;
`;
const StyledRadioGroup = styled(RadioGroup)`
  label span {
    font-size: 14px;
  }
`;
