import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableContainer,
  Paper,
} from "@mui/material";
import { SubmitButton } from "../../components/common/Buttons.tsx";
import moment from "moment";
import { dateFormat } from "../../constant.js";
import StudentService from "../../services/StudentService.js";

const today = moment().format("YYYY-MM-DD");
console.log("Today1: ", moment(today));
const AttendanceForm = () => {
  const [studentsData, setStudentData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classesSections, setClassesSections] = useState([]);
  const [selectedDate, setSelectedDate] = useState<null | Date>(new Date());
  const [selectedClass, setSelectedClass] = useState<null | string>(null);
  const [selectedSection, setSelectedSection] = useState<null | string>(null);
  const [attendanceDataObj, setAttendanceDataObj] = useState([{}]);

  useEffect(() => {
    StudentService.getAllClasses().then((data) => {
      setClasses(data);
      setSelectedClass(data[0].id);
    });

    StudentService.getAllClassSections().then((data) => {
      setClassesSections(data);
      setSelectedSection(data[0].id);
    });

    StudentService.getAllStudents().then((students) => {
      setStudentData(students);
      initAttendanceObj(students);
    });
  }, []);

  useEffect(() => {
    if (selectedClass && selectedSection && selectedDate) {
      const filterData = {
        date: moment(selectedDate).format("YYYY-MM-DD"),
        class_id: selectedClass,
        section_id: selectedSection,
      };

      StudentService.getAllStudentAttendance(filterData).then((data) => {
        console.log("attendance data: ", data);
        //  initAttendanceObj(data);
      });
    }
  }, [selectedDate, selectedClass, selectedSection]);

  const initAttendanceObj = (students) => {
    let attData = [];
    students.map((std) => {
      let data = {
        id: std.student_id,
        first_name: std.first_name,
        last_name: std.first_last,
        class_name: std.class_name,
        section_name: std.section_name ? std.section_name : selectedSection,
        father_name: std.father_name,
        class_id: std.class_id,
        section_id: std.section_id,
        student_id: std.id,
        present: std.present ? std.present : false,
      };
      attData.push(data);
    });
    setAttendanceDataObj(attData);
  };

  useEffect(() => {
    const { class_id, section_id } = attendanceDataObj;
    if (class_id && section_id) getStudentsList(attendanceDataObj);
  }, [attendanceDataObj]);

  const getStudentsList = (params) => {
    StudentService.getAllStudents(params).then((students) => {
      setStudentData(students);
    });
  };

  const handleRadioChange = (studentId, attendance) => {
    console.log("studentId, attendance: ", studentId, attendance);
    console.log("updatedData: ", attendanceDataObj);
    let isPresent = attendance === "present" ? true : false;
    const updatedData = attendanceDataObj.map((item) =>
      item.student_id === studentId
        ? { ...item, student_id: studentId, present: isPresent }
        : item
    );
    console.log("updatedData: ", updatedData);
    setAttendanceDataObj(updatedData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    StudentService.updateAttendance({
      date: selectedDate,
      data: attendanceDataObj,
    });
  };

  const listOfStudents = () => (
    <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{ background: "#265985", color: "white" }}>
          <TableRow style={{ background: "#265985", color: "white" }}>
            <TableCell
              style={{ color: "white", fontWeight: "bold", align: "left" }}
            >
              Student Name
            </TableCell>
            {/* <TableCell
              style={{ color: "white", fontWeight: "bold", align: "left" }}
            >
              Class
            </TableCell> */}
            {/* <TableCell
              style={{ color: "white", fontWeight: "bold", align: "left" }}
            >
              Section
            </TableCell> */}
            <TableCell
              style={{ color: "white", fontWeight: "bold", align: "left" }}
            >
              Father Name
            </TableCell>
            <TableCell
              style={{
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Attendance Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attendanceDataObj.map((student) => (
            <TableRow>
              <TableCell>
                {student?.first_name} {student?.last_name}
              </TableCell>

              <TableCell>{student?.father_name}</TableCell>
              <TableCell style={{ background: "#f7f7f7" }}>
                <RadioGroup
                  aria-label="attendance"
                  name="attendance"
                  defaultValue={student.present ? "present" : "absent"}
                  onChange={(e) =>
                    handleRadioChange(student.id, e.target.value)
                  }
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="present"
                    control={<Radio />}
                    label="Present"
                  />
                  <FormControlLabel
                    value="absent"
                    control={<Radio />}
                    label="Absent"
                  />
                  <FormControlLabel
                    value="leave"
                    control={<Radio />}
                    label="On Leave"
                  />
                </RadioGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  return (
    <Container component="main">
      <Grid container spacing={2}>
        <Paper style={{ marginBottom: "20px", padding: "20px", width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                label="Select Date"
                type="date"
                name="date"
                defaultValue={selectedDate}
                value={moment(selectedDate).format("YYYY-MM-DD")}
                onChange={(e) => setSelectedDate(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel htmlFor="selectedClass">Select Class</InputLabel>
                {selectedClass && (
                  <Select
                    label="selectedClass"
                    name="class_id"
                    onChange={(e) => setSelectedClass(e.target.value)}
                    defaultValue={selectedClass}
                    fullWidth
                    required
                  >
                    {classes?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.id}
                        selected={item.id == 1}
                      >
                        {item?.class_name}
                      </MenuItem>
                    ))}
                    {/* Add more classes as needed */}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel htmlFor="selectedClass">Select Section</InputLabel>
                {selectedSection && (
                  <Select
                    label="Selected Section"
                    name="section_id"
                    onChange={(e) => setSelectedSection(e.target.value)}
                    defaultValue={selectedSection}
                    //value={selectedSection}
                    fullWidth
                    required
                  >
                    {classesSections?.map((item, index) => (
                      <MenuItem key={index} value={item?.id}>
                        {item?.section_name}
                      </MenuItem>
                    ))}
                    {/* Add more classes as needed */}
                  </Select>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        {listOfStudents()}
        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          handleClick={handleSubmit}
          style={{ width: "260px", padding: "5px" }}
          label={"Submit Attendance"}
        />
      </Grid>
    </Container>
  );
};

export default AttendanceForm;
